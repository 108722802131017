import { Redirect } from "react-router-dom"
import styled from "styled-components"

import { PlanRenewalBreakdownTable } from "src/components/Account/BillingPortal/PlanRenewalBreakdown/PlanRenewalBreakdownTable"
import { PlanRenewalPeriod } from "src/components/Account/BillingPortal/PlanRenewalBreakdown/PlanRenewalPeriod"
import { PlanRenewalTotal } from "src/components/Account/BillingPortal/PlanRenewalBreakdown/PlanRenewalTotal"
import { CustomPlanInfoBox } from "src/components/Account/BillingPortal/YourPlan/CustomPlanInfoBox"
import { useAppData } from "src/context/useAppData"
import { useFetchBillingEvent } from "src/data/billing/queries/billingQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { translatePlanName } from "src/data/user/logic/userPlan"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { Divider } from "src/ui/Divider/Divider"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

export function BillingEventDetails({ eventId }: { eventId: string }) {
  const { currentSubscription } = useAppData()
  const { orgId } = useOrganization()

  const fetchBillingEvent = useFetchBillingEvent({
    orgId,
    eventId,
  })

  const { t, langKeys } = useTranslate()

  if (currentSubscription?.billing_model !== "plan_unit_per_home") {
    return <CustomPlanInfoBox />
  }

  if (fetchBillingEvent.isLoading && !fetchBillingEvent.data) {
    return (
      <div>
        <MSkeleton width="20ch" />
        <MSkeleton width="30ch" />
        <MSkeleton width="30ch" />
      </div>
    )
  }

  // We only care about `subscription_renewed` type at the moment
  if (fetchBillingEvent.data?.event_type !== "subscription_renewed") {
    return <Redirect to={Routes.AccountBilling.location().pathname} />
  }

  return (
    <BreadcrumbView
      title={
        <div>
          <MText variant="heading1">
            {t(langKeys.billing_event_plan_renewal_title, {
              date: formatDate({
                date: fetchBillingEvent.data.event_time || "",
                excludeTime: true,
              }),
            })}
          </MText>
        </div>
      }
      description={
        <DescriptionWrapper>
          <MText>
            {translatePlanName(
              t,
              fetchBillingEvent.data.metadata?.general_plan_type
            )}{" "}
            {t(langKeys.plan)},
          </MText>
          <PlanRenewalPeriod
            renewedFrom={fetchBillingEvent.data.metadata?.renewed_from ?? ""}
            renewedUntil={fetchBillingEvent.data.metadata?.renewed_until ?? ""}
            loading={fetchBillingEvent.isLoading}
          />
        </DescriptionWrapper>
      }
      breadcrumbs={[
        {
          to: Routes.AccountBilling.location(),
          label: t(langKeys.plan_renewal_estimate_breakdown_back_breadcrumb),
        },
        {
          to: Routes.BillingEventDetails.location(":eventId"),
          label: t(langKeys.billing_event_plan_renewal_breadcrumb),
        },
      ]}
    >
      <PlanRenewalTotal
        renewalAmount={
          fetchBillingEvent.data.metadata?.renewal_amount.formatted_amount
        }
        planAmount={
          fetchBillingEvent.data.metadata?.plan_amount.formatted_amount
        }
        addonAmount={
          fetchBillingEvent.data.metadata?.addon_amount.formatted_amount
        }
        isLoading={fetchBillingEvent.isLoading}
      />

      <Divider $margin={spacing.L} />

      <BreakdownWrapper>
        <PlanRenewalBreakdownTable
          breakdown={fetchBillingEvent.data.metadata?.renewal_breakdown}
          renewedUntil={fetchBillingEvent.data.metadata?.renewed_until}
          isLoading={fetchBillingEvent.isLoading}
          isLoadingNewData={fetchBillingEvent.isLoading}
        />
      </BreakdownWrapper>

      <MText variant="bodyS">
        *{t(langKeys.plan_renewal_breakdown_addon_note)}
      </MText>
    </BreadcrumbView>
  )
}

const DescriptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: ${spacing.XS};
`

const BreakdownWrapper = styled.div`
  margin-bottom: ${spacing.XL};
`
